import React from "react";
import { Link } from "react-router-dom";
import useFetchEvents from "./hooks/useFetchEvents";
import Loading from "./pages/loading";
import Error from "./pages/error";

const FrontEvents = () => {
  const protocol = window.location.protocol;
  let { loading, error, data } = useFetchEvents(
    `${protocol}//server.interface.edu.np/api/events`
  );
  if (loading) return <Loading />;
  if (error) return <Error />;
  //console.log("data  ", data.data[0].id);

  const getColNumber = (length) => {
    switch (length) {
      case 1:
        return "col-12";
      case 2:
        return "col-6";
      case 3:
        return "col-4";
      default:
        return "col-12";
    }
  };

  return (
    <div className="container p-4">
      <h2 className="pb-2 border-bottom">Events</h2>

      <div className="row g-4 py-5">
        {data && (
          <>
            {data.map((item, index) => (
              <div
                className={`col p-2 ${getColNumber(data.length)}`}
                key={index}
              >
                <div className="pt-3 px-3 text-center overflow-hidden">
                  <div className="p-3" style={{ height: "200px" }}>
                    <h2 className="display">{item.title}</h2>
                    <p className="lead fs-5">{item.tagline}</p>
                  </div>
                  <div
                    className="overflow-hidden"
                    style={{ maxHeight: "30vh" }}
                  >
                    <img
                      className="img-fluid shadow rounded-3 mb-4"
                      width="100%"
                      loading="lazy"
                      src={`${protocol}//server.interface.edu.np/uploads/cover/${item.coverImage}`}
                      alt="cover"
                    />
                  </div>
                </div>
                <div className="flex p-4">
                  <Link
                    to={`/event/${item.id}`}
                    type="button"
                    className="btn btn-primary float-end"
                  >
                    {" "}
                    Explore
                  </Link>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default FrontEvents;
