import React from "react";
import { useParams } from "react-router-dom";
import useFetchArticles from "../hooks/useFetchArticles";
import Loading from "./loading";
import Error from "./error";

const ArticleCard = () => {
  const { id } = useParams();
  const protocol = window.location.protocol;
  const { loadingA, errorA, articleData } = useFetchArticles(
    `${protocol}//server.interface.edu.np/api/articles/${id}?populate=*`
  );

  console.log(articleData);
  return (
    <>
      <div className="container p-4">
        <div className="col-md-12 p-lg-5 text-center mx-auto">
          {loadingA && <Loading />}
          {errorA && <Error />}
          {articleData && (
            <>
              <h3 className="display-5 fw-normal border-bottom text-center">
                {articleData.title}
              </h3>
              <h6 className="fw-normal text-center">
                {articleData.tagline}
              </h6>
              <div className="px-4 my-5 border-bottom">
                <div className="row">
                  <div className="col-12 col-md-10">
                    <div className="mx-auto lh-sm text-break text-start fs-6">
                      {articleData.body}
                    </div>
                    <h6 className="fw-normal text-center">
                      <span className="fs-5 fw-semibold">Keywords: </span>
                      {articleData.keywords}
                    </h6>
                  </div>
                  <div className="d-none d-md-block col-md-2 border-start">
                    <div className="p-2">
                      <div className="mb-3 mx-auto d-block overflow-hidden">
                        <div className="">
                          <img
                            className="img-fluid border rounded-5 shadow mb-4 img-fluid img-thumbnail h-50 w-50"
                            alt="author"
                            src={`${protocol}//server.interface.edu.np/uploads/author/${articleData.authorImage}`}
                          />
                        </div>
                        <div className="card-header fw-semibold">
                          <span
                            className="fw-medium fst-italic"
                            style={{ fontSize: "8px" }}
                          >
                            written by:{" "}
                          </span>
                          {articleData.author}
                        </div>
                        <div className="card-body">
                          <div
                            className="card-text text-start"
                            style={{ fontSize: "6px" }}
                          >
                            <div className="fw-medium text-decoration-underline">
                              about author
                            </div>
                            {articleData.authorDescription}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none col-12">
                <div className="p-2 py-4">
                  <div className="mb-3 mx-auto d-block overflow-hidden">
                    <div className="">
                      <img
                        className="img-fluid border rounded-5 shadow mb-4 img-fluid img-thumbnail h-25 w-25"
                        alt="author"
                        src={`${protocol}//server.interface.edu.np/uploads/author/${articleData.authorImage}`}
                      />
                    </div>
                    <div className="card-header fw-semibold">
                      <span
                        className="fw-medium fst-italic"
                        style={{ fontSize: "8px" }}
                      >
                        written by:{" "}
                      </span>
                      {articleData.author}
                    </div>
                    <div className="card-body">
                      <div
                        className="card-text text-start"
                        style={{ fontSize: "6px" }}
                      >
                        <div className="fw-medium text-decoration-underline">
                          about author
                        </div>
                        {articleData.authorDescription}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ArticleCard;
