import React, { useState } from "react";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const InterestedForm = () => {
    const [Flag, setFlag] = useState(false)
    const countries = ['Australia', 'Canada', 'USA']
    const courses = ['PhD', 'Masters', 'Bachelors', 'University Preparation', 'Vocational', 'English']
    const [values, setValues] = useState({ fname: '', lname: '', mobile: '', email: '', address: '', course: '', country: '', saveInfo: false })
    const set = name => { return ({ target: { value } }) => { setValues(oldValues => ({ ...oldValues, [name]: value })) } }

    const protocol = window.location.protocol; // Get the current protocol

    const FormHandler = async(e) => {
        const baseURL = `${protocol}//server.interface.edu.np/api/students/add`
        setFlag(true)
        e.preventDefault()
        await Axios.post(baseURL, {
            name: values.fname + " " + values.lname,
            mobile: values.mobile,
            email: values.email,
            address: values.address,
            course: values.course,
            country: values.country,
            save: values.saveInfo,
            date: new Date()
        }).then(() => {
            toast.success("Data Received, Thank you", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            console.log("success")
            setFlag(false)
            setTimeout(() => loadData(), 1500)
        });
    }

    const loadData = async () => {
        window.location.reload()
    }

    const handleChange = (e) => {

        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value
        setValues({
            ...values, [e.target.name]: value
        })
    }

    return (
        <div className="px-2 py-4">
            <div className="rounded bg-warning bg-opacity-70 pt-3 pt-md-5 text-dark overflow-hidden">
                <div className="container px-4">
                    <div className="my-3 py-3">
                        <h2 className="display-5">Need Assistance ??</h2>
                        <p className="lead">Fill up the form and get Callback from us.</p>
                    </div>
                    <div className="bg-more round-more border-bottom-0 bg-light shadow mx-auto">
                        <form className="needs-validation" onSubmit={FormHandler}>
                            <div className="row g-2">
                                <div className="col">
                                    <div className=""></div>
                                </div>
                                <div className="col"></div>
                                <div className="col-md-6 p-5">
                                    <div className="row py-2">
                                        <div className="col">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                placeholder="First Name *"
                                                required
                                                value={values.fname}
                                                onChange={set('fname')}
                                            />
                                            <div className="invalid-feedback">
                                                Valid first name is required.
                                            </div>
                                        </div>

                                        <div className="col">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastName"
                                                placeholder="Last Name *"
                                                value={values.lname}
                                                onChange={set('lname')}
                                                required
                                            />
                                            <div className="invalid-feedback">
                                                Valid last name is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 py-2">
                                            <div className="input-group has-validation">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="username"
                                                    placeholder="📱 Mobile Number *"
                                                    value={values.mobile}
                                                    onChange={set('mobile')}
                                                    required
                                                />
                                                <div className="invalid-feedback">
                                                    Your Mobile Number is required.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 py-2">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder="📧 you@example.com *"
                                                value={values.email}
                                                onChange={set('email')}
                                                required
                                            />
                                            <div className="invalid-feedback">
                                                Please enter a valid email address.
                                            </div>
                                        </div>

                                        <div className="col-12 py-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="address"
                                                placeholder="🪧 Address *"
                                                value={values.address}
                                                onChange={set('address')}
                                                required
                                            />
                                            <div className="invalid-feedback">
                                                Please enter your address.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 py-2">
                                        <label id="course" className="form-label text-start text-white">
                                            Prefered Study Course *
                                        </label>
                                        <select className="form-select" id="courses" value={values.course} onChange={set('course')} required>
                                            <option value="">Choose...</option>
                                            {courses.map(e => <option key={e}>{e}</option>)}
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select a valid Course Level.
                                        </div>
                                    </div>

                                    <div className="col-12 py-2">
                                        <label id="country" className="form-label text-start text-white">
                                            Prefered Study Destination *
                                        </label>
                                        <select className="form-select" id="country" value={values.country} onChange={set('country')} required>
                                            <option value="">Choose...</option>
                                            {countries.map(e => <option key={e}>{e}</option>)}
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select a valid country.
                                        </div>
                                    </div>

                                    <hr className="my-4" />

                                    <div className="form-check text-start text-white">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="terms"
                                            required
                                        />
                                        <label className="form-check-label" id="same-address">
                                            Agree Terms and Conditions.
                                        </label>
                                    </div>

                                    <div className="form-check text-start text-white">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="saveInfo"
                                            id="save-info"
                                            checked={values.saveInfo}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" id="save-info">
                                            Save this information for evaluation.
                                        </label>
                                    </div>
                                    <hr className="my-2" />
                                    <div className="form-check text-start text-white fw-bold justify-content-between">
                                        <label className="form-check-label" id="info">
                                            We respect your privacy and do not share personal information.
                                        </label>
                                    </div>
                                    <hr className="my-2" />
                                    <button
                                        className="w-100 btn btn-primary btn-lg"
                                        type="submit"
                                        disabled={Flag}>
                                        Send Request
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default InterestedForm;
